
/** @jsx jsx */
import MySEO from '../../components/seo'
import {StaticImage} from 'gatsby-plugin-image'
import {Box,jsx,Container,Flex,Text,Heading} from 'theme-ui'
import Layout from '../../components/layout'

export default function KonzertKulturhaus (){
    return(
        <Layout>
        <Flex>

 <Container sx={{m:3}}>

 <MySEO title="Konzert im Kulturhaus Oberwesel" description = "Konzert im Kulturhaus Oberwesel " />


<Box sx={{maxHeight:600,marginBottom:18}}>
<StaticImage src="../../images/veranstaltungen/Alarcon_Reiter-2.jpg" layout="constrained" 
alt="Bild von Benjamin Reiter &amp; Ana Paola Alarcón"  />
<Text variant="photo">©Benjamin Reiter</Text>
</Box>

<Heading style={{color:'red'}}>Die Veranstaltungen wird wegen Corona  erst im Jahr 2022 stattfinden.<br/> Sobald ein neuer Termin festgelegt worden ist, wird er hier an dieser Stelle veröffentlicht.</Heading>     
<Heading>Konzert zwischen Klassik u. Moderne <br/>
Festivalprogramm im Kulturhaus<br/>

</Heading>
<Box sx={{pr:2}}>

<Text variant="absatz">
Lassen Sie sich kurz vor Weihnachten noch einmal musikalisch verführen mit <span style={{fontWeight:500}}>Ana Paola Alarcón (Violine)</span> und <span style={{fontWeight:500}}>Benjamin Reiter (Klavier)</span>. </Text>
<Text variant="absatz">In ihrem abwechslungsreichen Programm am 17.12.2021 um 19 Uhr im Kulturhaus Oberwesel bauen sie <span style={{fontStyle:'italic'}}>eine Brücke von der traditionellen deutschen Klassik über die französisch-belgische Spätromantik bis zur zeitgenössischen klassischen Musik Lateinamerikas</span>.
</Text>
<Text variant="absatz">
Der gebürtige Bacharacher Benjamin Reiter und Ana Paola Alarcón präsentieren ein Repertoire von großer Spannbreite: </Text>
<Text variant="absatz">Die Sonate D-Dur Op. 12 Nr. 1 von <span style={{fontWeight:500}}>Ludwig van Beethoven</span> ist eine stets frisch wirkende Eröffnung, während die große Violinsonate von <span style={{fontWeight:500}}>Caesar Franck </span>eine vielfältige Klangwelt zwischen verträumt-atmosphärischen Flächen und höchster Dramatik eröffnet.
</Text>  
<Text mb="3" variant="absatz">
Mit dem zeitgenössischen, ursprünglich als Bühnenmusik zum gleichnamigen Theaterstück konzipierten Werk „Diogenes y las camisas voladoras“ des <span style={{fontWeight:500}}>venezolanischen Komponisten Icli Zitella</span>, den Ana Paola Alarcón aus ihrer Heimatstadt Caraccas persönlich kennt, wird ein spannungsvoller Kontrast gesetzt. 
</Text>

<Text>
    <a rel="noopener noreferrer" href="https://www.ticket-regional.de/events_info.php?eventID=182012e"><Text>&rarr; Online-Ticket bestellen</Text></a>
</Text>
<Text mt="4" variant="fettmt">
Für die Veranstaltung gilt 2G (= geimpft oder genesen). 
</Text>
<Text mb="4" mt="2" >
Die Veranstaltung findet in Kooperation mit dem Kulturnetz Oberes Mittelrheintal (K.O.M.) e.V. statt. 
</Text>
</Box>



</Container>

</Flex>
</Layout>

    )
}



